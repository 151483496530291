main.project-page {
  min-height: 100vh;
  padding: 30px 0;
  padding-top:0;
  transition: background-color 3s;
}

main.project-page.color-transition {
  background-color: #FAFAF9;
}

.color-change-enter {
  background-color: #fff;
}
.color-change-enter-active {
  background-color: #FAFAF9;
  transition: background-color 3s;
}

main.project-page h1, main.project-page h2 {
  text-align: center;
}

main.project-page h3 {
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  margin-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
}

main.project-page header {
  margin-bottom: 40px;
}

.project-page-hero {
  animation-duration: 1s;
}

.project-backbar {
  padding: 20px;
}

.project-backbar a {
  text-decoration: none;
  color: inherit;
  font-weight: 700;
}

.project-backbar a:hover {
  color: #FD385B;
}

main.project-page a.project-link {
  display: block;
  width: 180px;
  text-align: center;
  background-color: transparent;
  color: #000;
  border: 1px solid #979797;
  border-radius: 20px;
  margin: 0 auto;
  padding: 5px 0;
  font-weight: 600;
  text-decoration: none;
}

main.project-page h1 {
  font-size: 64px;
  color: #D0021B;
  line-height: 58px;
  /*text-transform: uppercase;*/
  font-weight: 700;
  margin-top: 20px;
  word-break: break-all;
}

main.project-page strong {
  font-weight: 600;
  font-style: italic;
}

main.project-page h2 {
  color: rgba(60, 60, 60, .7);
  font-size: 22px;
  line-height: 49px;
  margin-bottom: 39px;
}

article.project-description, article.project-skills {
  color: #4A4A4A;
  font-size: 16px;
  line-height: 28px;
  margin: 0 auto;
  max-width: 750px;
  padding: 0 20px;
  margin-bottom: 29px;
}

ul.project-images {
  padding: 0 30px;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
  animation-duration: 1s;
}

ul.project-images li {
  margin-bottom: 50px;
  text-align: center;
}

ul.project-images li img {
  max-width: 100%;
}

ul.project-images li img:not(.has-shadow) {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
}

@media (max-width: 599px) {
  ul.project-images li img {
    border-radius: 5px;
  }
  
  ul.project-images li img:not(.has-shadow) {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .5);
  }

  main.project-page h3 {
    font-size: 18px;
  }

  main.project-page h1 {
    font-size: 32px;
    line-height: 1.2;
  }

  main.project-page h2 {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  ul.project-images {
    padding: 0 10px;
  }
}
