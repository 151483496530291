article.my-skills {
  padding: 33px;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, .5);
  border-radius: 5px;
  margin-bottom: 50px;
}

ul.ms-items {
  display: flex;
  flex-wrap: wrap;
}

li.ms-item, li.ms-item-resume {
  border-radius: 5px;
  padding: 20px 28px;
  min-height: 212px;
  margin: 0 2%;
}

li.ms-item {
  width: 100%;
}

li.ms-item:nth-child(2) {
  margin-bottom: 40px;
}

li.ms-item.other-skills {
  width: 44%;
}

li.ms-item-resume {
  background-image: url("/public/images/resume/resume-part.png");
  background-size: cover;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 47%;
  display: flex;
}

a.view-resume-btn {
  width: 250px;
  height: 87px;
  background-color: #fff;
  color: #4A4A4A;
  position: absolute;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 5px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, .5);
  text-decoration: none;
  transition: background-color, color .2s ease-in-out;
}

a.view-resume-btn:hover {
  background-color: #FD385B;
  color: #fff;
}

li.ms-item-resume::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #41295A;
  opacity: .8;
  z-index: 99;
  bottom: 0;
  right: 0;
  border-radius: 5px;
}

li.ms-item:not(.other-skills) .ms-item-title {
  padding: 0 10px;
}

.ms-item-skills {}

.ms-item-skills img {
  max-width: 100%;
}

li.ms-item h4 {
  font-size: 20px;
  color: #5F5F5F;
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 28px;
}

.ms-item-skills ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.ms-item-skills li {
  width: 50%;
  flex: 1 auto;
  padding: 15px 10px;
}

article.my-skills h3 {
  font-size: 36px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 40px;
}

.resume-skills {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 30px;
}

.ipad, .resume {
  width: 50%;
}

.ipad {
  position: relative;
  padding: 115px 40px;
}

.ipad-svg {
  width: 100%;
  vertical-align: middle;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}

.ipad-svg .svg-obj {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.skill-name {
  margin-bottom: 5px;
}

.skill-name > span:last-child {
  float: right;
}

.skill-progress {
  height: 20px;
  width: 100%;
  border: 1px solid #CBCBCB;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}

.sp-bar {
  flex: 1;
/*  background-color: #92EAC0;*/
  background-color: #cbcbcb;
  width: 0%;
  animation: 1s ease forwards;
}

.progress-65 {
  animation-name: w65;
}

.progress-80 {
  animation-name: w80;
}

.progress-85 {
  animation-name: w85;
}

.progress-90 {
  animation-name: w90;
}

.progress-95 {
  animation-name: w95;
}

.w80 { width: 65%; }

.w80 { width: 80%; }

.w85 { width: 85%; }

.w90 { width: 90%; }

.w95 { width: 95%; }

@keyframes w65 {
  from {width: 0%}
  to {width: 65%}
}

@keyframes w80 {
  from {width: 0%;}
  to {width: 80%;}
}

@keyframes w85 {
  from {width: 0%;}
  to {width: 85%;}
}

@keyframes w90 {
  from {width: 0%;}
  to {width: 90%;}
}

@keyframes w95 {
  from {width: 0%;}
  to {width: 95%;}
}

/* tablet landscape down */
@media (max-width: 900px) {
  li.ms-item:nth-child(2) {
    margin-bottom: 20px;
  }

  article.my-skills {
    padding: 20px 0;
  }

  li.ms-item.other-skills {
    padding-top: 0;
  }

  li.ms-item {
    padding: 20px;
  }

  .ms-item-skills img {
    margin: 0 auto;
    display: block;
  }
}

/* tablet portrait down */
@media (max-width: 720px) {
  li.ms-item.other-skills {
    width: 100%;
    margin-bottom: 20px
  }

  li.ms-item-resume {
    width: 100%;
  }
}

/* iphone */
@media (max-width: 599px) {
  article.my-skills  {
    margin: 0 10px;
    margin-bottom: 20px;
  }

  .ms-item-skills li {
    width: 100%;
  }

  .ms-item-skills {
    margin-bottom: 20px;
  }
  
  li.ms-item.other-skills {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  li.ms-item {
    min-height: 160px;
    margin: 0;
    padding: 10px;
  }

  li.ms-item li {
    padding: 14px 7px;
  }

  li.ms-item-resume {
    padding: 0;
  }
}
