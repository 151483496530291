html {
  box-sizing: border-box;
}

button {
  outline: none;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

main {
  font-family: Open Sans, "Lato", Helvetica;
  position: relative;
}

img.img-shadow {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
  border-radius: 5px;
}

.pattern-background {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -2;
  overflow: hidden;
}

.pattern-background svg.triangle {
  width: 120% !important;
  left: -10%;
  position: relative;
  opacity: .8;
  float: left;
}

.pattern-background svg.triangle.inverted {
  transform: rotate(180deg);
}

.main-projects, .hero-section, header >div {
  max-width: 1080px;
  margin: 0 auto;
}

body {
  background-color: #F5F8FB;
  overflow-x: hidden;
}

header {
  margin-bottom: 73px;
  padding: 20px;
  animation-duration: 1s;
  padding-bottom: 0;
}

header > div {
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
}

.main-projects {
  /*overflow-y: hidden;*/
  position: relative;
  padding-bottom: 130px;
}

.maintanence-updates {
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  max-width: 650px;
  border: 1px solid #eee;
  min-height: 200px;
  margin-top: 40px;
  margin-bottom: 40px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);;
}

.maintanence-updates h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.5;
}

/*.main-projects::before {
  content: " ";
  width: 1px;
  top: 0;
  left: 50%;
  background-color: #e0e0e0;
  display: block;
  position: absolute;
  z-index: -1;
  bottom: -100px;
}*/

.header-links {
  display: flex;
}

.back-link:not(:empty) {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header-link {
  margin-left: 48px;
  cursor: pointer;
}

.header-link a {
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding-bottom: 5px;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border .1s ease-in-out;
}

.header-link a:hover {
    border-bottom: 1px solid #ccc;
}

.header-link a.active {
  border-bottom: 1px solid black;
}

.hero-section {
  text-align: center;
  max-width: 584px;
  margin: 0 auto;
  overflow: hidden;
}

.hero-section .my-name {
  font-size: 38px;
  font-weight: 200;
  color: #4A4A4A;
}

.hero-section h1 {
  font-size: 100px;
  line-height: 1.2;
  font-weight: 700;
  color: #4A4A4A;
}

.hero-section h2 {
  font-size: 24px;
  font-weight: 200;
  margin: 0 auto;
  margin-bottom: 43px;
  color: #4A4A4A;
  max-width: 333px;
  line-height: 1.4;
}

.hero-section button {
  width: 300px;
  border-radius: 50px;
  background-color: #FD385B;
/*  background-color: #fff;*/
  /*background-color: rgb(35, 7, 77);*/
/*  box-shadow: 2px 2px 4px rgba(0,0,0,.5);*/
  font-size: 24px;
  margin-bottom: 10px;
  padding: 23px 0;
  color: #fff;
  max-width: 100%;
  font-weight: 700;
  border: 0;
/*  border: 1px solid #333;*/
/*  color: #333;*/
}

.project-panel {
  max-width: 100%;
  padding-top: 115px;
  position: relative;
}

.project-panel .link-bubble, .sub-project-panels .link-bubble {
  border: 1px solid #979797;
  width: 160px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 0;
  display: block;
  background-color: transparent;
  margin: 0 auto;
  margin-top: 5px;
  transition: border, background-color .1s ease-in-out;
  text-decoration: none;
  color: inherit;
}

.sub-project-panels .link-bubble {
  color: #000;
}

.project-panel .link-bubble:hover {
  background-color: #FD385B;
  color: #fff;
  border: 1px solid #FD385B;
}

.project-panel .spacer {
  padding-top: 145px;
}

.project-panel .time-bubble {
  display: flex;
  position: absolute;
  top: 134px;
  left: 49%;
}

.time-bubble span {
  font-size: 24px;
  font-weight: 900;
  margin-left: 20px;
}

.project-panel .time-bubble::before {
  content: " ";
  width: 24px;
  height: 24px;
  display: block;
  border-radius: 30px;
  background-color: #4A4A4A;
}

.project-panel h3 {
  font-size: 64px;
  margin-bottom: 5px;
  font-weight: 700;
  text-align: center;
}

.project-panel h3 a {
  text-decoration: none;
  color: inherit;
  transition: color .1s ease-in-out;
}

.project-panel h3 a:hover {
  color: #FD385B;
}

.project-panel p {
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 200;
}

.project-panel-imgs {
  text-align: center;
  display: flex;
  justify-content: center;
}

.project-panel-imgs img {
  display: inline-block;
  max-width: 100%;
}

img.img-smaller {
  padding: 10px 0;
}

.sub-project-panels {
  position: relative;
  background: linear-gradient(to right, #293241, #232a37);
  /*background: #293241;*/
  color: #fff;
  padding: 50px 20px 30px 20px;
  overflow-y: scroll;
}

.sub-project-panels {
  scrollbar-width: none
}

.sub-project-panels::-webkit-scrollbar {
    display: none;
    -webkit-overflow-scrolling: touch;
}

.sub-project-panels .inner::after {
  content: " ";
  bottom: 78px;
  left: -20%;
  height: 1px;
  width: 140%;
  background-color: #979797;
  position: absolute;
  display: block;
}

.sub-project-panels .inner {
  display: flex;
  width: 1000px;
  margin: 0 auto;
  position: relative;
}

.sub-time {
  font-weight: 700;
}

.sub-project-panel {
  text-align: center;
  width: 30%;
  position: relative;
}

.sub-project-panel figure {
  position: relative;
  display: inline-block;
  margin-bottom: 73px;
}

.sub-project-panel figure:hover::after {
  opacity: .7;
}

.sub-project-panel figure:hover .link-bubble {
  opacity: 1;
}

.sub-project-panel figure::after {
  content: " ";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  position: absolute;
  transition: opacity .3s;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
}

.sub-project-panel .link-bubble {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  opacity: 0;
  top: 50%;
  z-index: 999;
  background-color: #fff;
}

.sub-project-panel a {
  text-decoration: none;
  color: inherit;
  transition: color .1s ease-in-out;
  cursor: pointer;
}

.sub-project-panel a:hover {
  color: #FD385B;
}

.sub-project-panel h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 7px;
}

.sub-project-panel p {
  font-size: 16px;
  line-height: 25px;
  padding: 0 35px;
}

.sub-project-panel img {
  max-width:100%;
}

.sub-project-panel::after {
  content: " ";
  display: block;
  width: 1px;
  height: 30px;
  background-color: #979797;
  left: 50%;
  bottom: 64px;
  position: absolute;
}

.sub-project-panel:nth-child(2) {
  margin: 0 50px;
}

footer {
  /*background-color: #11051E;*/
  background-color: #232a37;
  text-align: center;
  padding: 33px 20px 70px 20px;
  color: #fff;
}

footer h4 {
  font-size: 64px;
  font-weight: 700;
  line-height: 98px;
  margin-bottom: 19px;
}

footer button {
  background-color: #FD385B;
  font-size: 24px;
  padding: 24px 0;
  width: 368px;
  max-width: 100%;
  color: #fff;
  border-radius: 5px;
  font-weight: 700;
  border: 0;
  border-radius: 50px;
  max-width: 100%;
}

.maintenance-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 15px;
  background-color: #fff;
  border-top: 1px solid #eee;
  color: #333;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
}

.project-slider {
  position: relative;
  text-align: center;
}

.project-slider .nav-arrow {
  border: 0;
  background: transparent;
  width: auto;
}

.ps-content {
  padding: 0 40px;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}

.project-panel-imgs:not(.ps-panels) {
  padding: 0 30px;
}

.project-panel-imgs.ps-panels {
  max-width: 850px;
  position: relative;
}

.project-panel-imgs.ps-panels .project-panel-img {
  opacity: 0;
}

.project-panel-imgs.ps-panels .project-panel-img:not(.active){
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.project-panel-imgs.ps-panels .project-panel-img.active {
  opacity: 1;
}

.project-slider .ps-nav-arrow::before {
  content: " ";
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 7px;
}

.section-header {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.section-header h2 {
  font-size: 36px;
  text-align: center;
}

.section-header::after {
  content: " ";
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 12px;
  transform: rotate(42deg);
}

button.ps-nav-arrow {
  position: absolute;
  top: 50%;
  border: 0;
  background: transparent;
  height: 34px;
  width: 24px;
  text-align: left;
  padding: 0;
  outline: none;
}

.ps-nav-arrow.backward {
  left: 0px;
  text-align: right;
}

.ps-nav-arrow.forward {
  right: 0px;
  text-align: left;
}

.ps-nav-arrow.backward::before {
  transform: rotate(135deg);
}

.ps-nav-arrow.forward::before {
  transform: rotate(-45deg);
}

ul.ps-tablist {
  width: 120px;
  margin: 0 auto;
  display: block;
  text-align: center;
}

ul.ps-tablist li {
  display: inline-block;
  padding: 0 5px;
  margin-bottom: 15px;
  cursor: pointer;
}

ul.ps-tablist li::after {
  content: " ";
  width: 12px;
  height: 12px;
  background-color: #ccc;
  border-radius: 10px;
  display: block;
  opacity: .7;
}

ul.ps-tablist li.active::after {
  background-color: #4A4A4A;
}

button.showLink {
  transition: width .3s ease-in-out;
}

button.showLink span:last-child {
  display: none;
  position: relative;
}

button.showLink:hover {
  width: 350px !important;
  /*background-color: #FD385B;
  color: #fff;
  border: 1px solid transparent;*/
}

button.showLink:hover span:last-child {
  display: block;
  animation: fadeInUp .5s;
}

button.showLink:hover span:first-child {
  display: none;
}


.subtleSlideUp {
  will-change: opacity, transform;
  animation-name: subtleSlideUp;
  position: relative;
}

.subtleSlideDown {
  will-change: opacity, transform;
  position: relative;
  animation-name: subtleSlideDown;
}

.subtleSlideRight {
  will-change: opacity, transform;
  position: relative;
  animation-name: subtleSlideRight;
}

@keyframes subtleSlideRight {
  from {
    opacity: .7;
    transform: translateX(4%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes subtleSlideDown {
  from {
    opacity: .7;
    transform: translateY(-30%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes subtleSlideUp {
  from {
    opacity: .7;
    transform: translateY(4%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* tablet portrait down */
@media (max-width: 720px) {
  .hero-section h1 {
    font-size: 90px;
  }

  .hero-section .my-name {
    font-size: 32px;
  }

  .hero-section h2 {
    font-size: 22px;
    margin-bottom: 23px;
  }

  .project-panel h3 {
    font-size: 56px;
  }

  .project-panel p {
    font-size: 22px;
  }
}

/* tablet landscape down */
@media (max-width: 900px) {
  .ps-content {
    margin-right: 30px;
    margin-left: 30px;
  }
}

/* iphone */
@media (max-width: 599px) {
  .header-link a {
    font-size: 16px;
  }

  .project-slider {
    padding: 0 10px;
  }

  header {
    margin-bottom: 24px;
    background-color: #fff;
    padding-bottom: 23px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1);
  }

  footer button {
    width: 200px;
    font-size: 20px;
    padding: 15px 0;
  }

  .hero-section h2 {
    max-width: 300px;
    font-size: 20px;
  }

  .hero-section .my-name {
    font-size: 28px;
  }

  .header-link {
    margin: 0;
    padding: 0 10px;
  }

  .time-bubble span {
    font-size: 18px;
  }

  .project-panel .time-bubble::before {
    width: 18px;
    height: 18px;
  }

  header > div {
    justify-content: center;
  }

  footer h4 {
    font-size: 40px;
    line-height: 1.2;
  }

  .ps-content {
    margin-right: 20px;
    margin-left: 20px;
    padding: 0 30px;
  }

  .hero-section {
    padding: 0 20px;
  }

  .hero-section h1 {
    font-size: 60px;
  }

  .project-panel h3 {
    font-size: 36px;
  }

  .project-panel p {
    font-size: 15px;
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .project-panel .time-bubble {
    left: 48%;
  }

  .project-panel {
    padding-top: 0;
  }

  .project-panel .time-bubble {
    top: 70px;
  }

  .project-panel-imgs:not(.ps-panels) {
    padding: 0 10px;
  }

  .ps-content {
    margin-right: 0;
    margin-left: 0;
  }
}

/* desktop up */
@media (min-width: 1200px) {}

/* big desktop up */
@media (min-width: 1800px) { }

@media (max-width: 599px) {
  .maintanence-updates {
    margin-right: 20px;
    margin-left: 20px;
  }

  .maintenance-footer {
    font-size: 16px;
  }

  .maintanence-updates h2 {
    font-size: 24px;
  }

  .hero-section button {
    width: 200px;
    padding: 15px 0;
    font-size: 20px;
  }

  .main-projects {
    margin: 0 10px;
  }

  button.showLink:hover {
    width: 250px !important;
  }
}
