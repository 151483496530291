.my-about {
	display: block;
	margin: 0 auto;
	width: 700px;
	background-color: #fff;
	height: 500px;
	box-shadow: 2px 3px 7px rgba(0,0,0,.5);
	margin-top: 40px;
	margin-bottom: 40px;
	border-radius: 10px;
}